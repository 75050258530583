<template>
  <div>
    <div style="height: 20px"></div>
    <!-- 媒体组件 -->
    <!-- <div class="media-box"> -->
      <!-- <div class="media-item left-box"> -->
        <!-- <btc-video v-if="item.VideoLink" :item="item" style="height: 100%;width: 100%;min-width: 711px;margin: 0;border-radius: 0"/> -->
        <!-- <img v-if="!item.VideoLink" style="height: 100%;width: 100%" :src="item.NewsTitlePic" alt=""> -->
      <!-- </div> -->
     <!-- <div v-show="firstItem.ReportID === item.ReportID" class="media-item right-box">-->
       <!-- <div class="right-body"> -->
         <!-- <div class="right-title"> -->
           <!-- <h2>必孚市场分析</h2> -->
         <!-- </div> -->
         <!-- <div class="right-footer"> -->
           <!-- <img style="margin-top: 10px;width: 18px;height: 15px" src="@/assets/image/price/analysis/up.png" alt=""> -->
           <!-- <div style="margin-top: 60px;text-align: center;font-family: 'AbhayaLibre Regular';padding: 0 20px 0 10px;width: 280px">让市场听见您的声音</div> -->
         <!-- </div> -->
       <!-- </div> -->
   <!-- </div> -->
    <!-- </div> -->
    <!-- 文章详情 -->
    <div class="article-details">
      <h5>{{ item.NewsTitle }}</h5>
      <div class="week-body">
        <div>
          <img src="@/assets/image/price/Date.png" alt="">
          <span>{{ handleDate(item.NewsTitle) }}</span>
        </div>
        <!-- <div v-show="isSwitch" class="week-language">
          <div v-for="(item, index) in langs" :key="index" class="hand" :class="{ active: current === index }" @click="handleLanguage(index)">
            <img v-if="current === index" src="@/assets/image/price/analysis/dot.png" alt="">
            <img style="width: 28px;height: 20px" :src="item.icon" :alt="item.lang" class="hand">
          </div>
        </div> -->
      </div>
      <div class="week-content">
        <input type="checkbox" id="contTab" checked="checked" class="tabbed">
        <div id="contCn">
          <viewer v-if="item.NewsContext" :key="item.NewsID" :initialValue="item.NewsContext"/>
          <div style="width: 100%;display: flex;justify-content: flex-end">
            <!-- 声明 -->
            <div class="logo-box">
              <div>
                <img src="@/assets/image/btcinfo/logo.png" style="width: 100px;">
                <span style="font-size: 12px; margin: 0 10px;">【来源: BTC必孚（中国）】</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content-more">
          <div class="gradient"></div>
          <label class="readme" @click="handleKeep">查看更多
            <img style="margin-left: 10px" src="@/assets/image/price/analysis/keep.png" alt="">
          </label>
        </div>
      </div>
    </div>
    <div class="divider"/>
    <div class="week-footer">
      <div class="week-box">
        <div class="week-year">
          <span>年份：</span>
          <div v-for="(item, index) in year" :key="index" class="table-item hand" :class="{ active: current === index }" @click="handleTabItem(index)">{{item}}</div>
        </div>
        <div class="week-bodylist">
          <media-card v-for="(item,index) in list" :key="index" :item="item" @handleMedia="handleMedia(item)"/>
        </div>
      </div>
      <!-- <media-card v-for="(item,index) in list" :key="index" :item="item" @handleMedia="handleMedia(item,index)"/> -->
      <!-- <div class="week-more hand" @click="handleMore">
        <img src="@/assets/image/price/analysis/more.png" alt="">
      </div> -->
    </div>
    <div class="week-footer1">
      <pagination :total="total" :page.sync="ListQuery.PageNum" :size.sync="ListQuery.PageSize" @pagination="getList1"/>
    </div>
  </div>
</template>

<script>
// import MediaCard from './components/week-more'
// import BtcVideo from '@/components/BtcVideo'
import { Viewer } from '@toast-ui/vue-editor'
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'
import MediaCard from './components/media-card'
import Pagination from '@/components/pagination'

export default {
  mixins: [commonmix],
  name: 'CnMarketAnalysis',
  components: {
    MediaCard,
    Pagination,
    Viewer
  },
  props: {
    defaultItems: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: () => {}
    }
  },
  data () {
    return {
      current: 0,
      ListQuery: {
        PageNum: 1,
        PageSize: 9,
        DailyType: '',
        Year: '2025',
        UserID: getLocalStore().UserID
      },
      list: [],
      item: {},
      firstItem: {},
      isSwitch: false,
      langIcon: [
        { lang: 'en', icon: require('@/assets/image/btcinfo/american.png') },
        { lang: 'spanish', icon: require('@/assets/image/btcinfo/spanish.png') },
        { lang: 'portuguese', icon: require('@/assets/image/btcinfo/portuguese.png') }
      ],
      currentIndex: 0,
      total: 0,
      year: ['2025', '2024', '2023', '2022'],
      yearlist: [],
      allList: []
    }
  },
  mounted () {
    this.current = 0
    this.getList()
    this.getList1()
  },
  computed: {
    isEnExpire () {
      return this.getUserProfile(this.$t('message._beefmarketanalysis'))
    },
    langs () {
      if (this.list.length) {
        const newArr = [...this.list[this.currentIndex].Langs]
        if (newArr.length !== 0) {
          newArr.unshift('en')
        }
        const arr = []
        newArr.forEach(item => {
          this.langIcon.forEach((v, index) => {
            if (v.lang === item) {
              arr.push(this.langIcon[index])
            }
          })
        })
        return arr
      }
      return []
    }
  },
  methods: {
    getList () {
      // console.log(params)
      this.ListQuery.DailyType = this.selected
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebNewsAnalysis', this.ListQuery).then(() => {
        this.firstItem = this.$store.getters.priceRows[0]
        this.list = this.defaultItems.length === 0 ? this.$store.getters.priceRows : this.defaultItems
        this.item = this.list[0]
        console.log(this.list)
        this.isSwitch = this.item.Langs.length !== 0
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        this.list = []
        this.item = {}
        console.log(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getList1 (isAll = false) {
      this.ListQuery.DailyType = this.selected
      console.log(this.selected)
      this.$store.dispatch('GetWebNewsAnalysis', this.ListQuery).then(() => {
        this.total = this.$store.getters.priceTotal
        this.list = this.$store.getters.priceRows
        this.item = this.list[0]
        if (isAll) {
          this.getAllList()
        }
      }).catch((err) => {
        this.list = []
        this.item = {}
        console.log(err)
      })
    },
    getAllList () {
      this.ListQuery.DailyType = this.selected
      const allQuery = Object.assign({}, this.ListQuery)
      allQuery.PageSize = this.total
      this.$store.dispatch('GetWebNewsAnalysis', allQuery).then(() => {
        this.allList = this.$store.getters.priceRows
      }).catch((err) => {
        this.list = []
        this.item = {}
        console.log(err)
      })
    },
    handleTabItem (index) {
      this.current = index
      this.ListQuery.Year = this.year[index]
      this.ListQuery.PageNum = 1
      this.list = []
      this.getList1(true)
    },
    // handleMedia (index) {
    //   const sliceList = this.allList.slice(0).splice(index + (this.ListQuery.PageSize * (this.ListQuery.PageNum - 1)), 3)
    //   this.$emit('handleNoMore', sliceList)
    // },
    handleLanguage (index) {
      this.current = index
      if (index === 0) {
        this.item = this.list[0]
      } else {
        const listIndex = index - 1
        this.item = this.list[this.currentIndex].Recommendation[listIndex]
      }
    },
    handleMore () {
      this.$emit('handleMore')
    },
    handleMedia (item, index) {
      console.log(6666666)
      // this.current = 0
      this.currentIndex = index
      this.item = item
      console.log(item)
      this.isSwitch = this.item.Langs.length
      window.scrollTo(0, 0)
    },
    handleDate (str) {
      if (str) {
        return str.substring(str.lastIndexOf('（') + 1, str.lastIndexOf('）'))
      }
    },
    handleKeep () {
      console.log(this.item)
      if (this.$store.getters.token) {
        if (this.isEnExpire || this.item.IsTopNews) {
          document.getElementById('contTab').checked = false
        } else {
          this.openCnMembershipModal()
        }
      } else {
        this.openCnLoginModal()
      }
    }
  }
}
</script>

<style lang="scss">
#contCn .tui-editor-contents > h4 {
  font-size:16px!important;
  line-height: 24px;
  font-family:"AbhayaLibre Regular";
  word-break: normal!important;
}
</style>

<style lang="scss" scoped>
.media-box {
  height: 400px;
  display: flex;
  background: #ffffff;
}

.media-item {
  height: 100%;
}

.left-box {
  flex: 1;
}

.right-box {
  width: 280px;
  color: #F9F9F9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-title {
  padding: 40px;
}

.right-title > h2 {
  font-family:"AbhayaLibre ExtraBold";
  font-size: 40px;
}

.right-body {
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: linear-gradient(to right, #055585, #014479);
}

.right-footer {
  height: 100%;
  background-image: linear-gradient(to right, #055585, #014479);
  text-align: center;
  transition: 0.3s;
  position: absolute;
  margin-top: 325px;
  top: 1px;
  z-index: 1;
}

.right-body :hover.right-footer {
  opacity: 1;
  transition: 0.3s;
  margin-top: 160px;
  font-size: 18px;
}

.article-details > h5 {
  flex: 1;
  text-align: center;
  margin: 20px 0;
  font-family:"AbhayaLibre ExtraBold";
  font-size: 24px!important;
}

.week-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.week-body > div:first-child {
  display: flex;
  align-items: center;
  font-size:20px!important;
  line-height: 27px;
  font-family:"AbhayaLibre ExtraBold";
}

.week-body > div:first-child > img {
  width: 20px;
  height: 20px;
  margin: 10px;
}

.week-language {
  height: 30px;
  max-width: 165px;
  background: #e6e6e6;
  border-radius: 8px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.week-language > div {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.week-language > div > img {
  padding: 2px;
}

.week-content {
  padding: 10px;
  height: 100%;
}

.active {
  border-radius: 10%;
  background: #ffffff;
}

.week-footer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.week-more {
  width: 40px;
  height: 100px;
  margin: 20px 0;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 1px #e1e1e1;
}

/*阅读全文*/
.tabbed {
  display: none;
}

.content-more {
  display: none;
}

.tabbed:checked ~ #contCn {
  max-height: 30px;
  overflow: hidden;
}

.tabbed:checked ~ .content-more {
  display: block;
  position: relative;
  padding: 40px 0 0 0;
  text-align: center;
}

.tabbed:checked ~ .content-more .gradient {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#f5f5f5));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), #f5f5f5);
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0), #f5f5f5);
  height: 80px;
  position: absolute;
  left: 0;
  top: -79px;
  width: 100%;
}

.tabbed:checked ~ .content-more .readme {
  display: inline-block;
  background: #ffffff;
  color: #014479;
  width: 160px;
  height: 35px;
  border-radius: 35px;
  line-height: 35px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 1px 1px 1px #e1e1e1;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #FFFFFF;
}

.logo-box {
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
}

.logo-box > div {
  display: inline-flex;
  align-items: center;
  box-shadow: 0 0 5px #e0e0e0;
  padding: 5px;
  border-right: 3px solid #a5c8e6;
  border-radius: 2px;
}

.week-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.week-year {
  padding: 20px 0 20px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: bold;
}

.week-year > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 60px;
  margin: 0 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #e1e1e1;
}

.week-bodylist {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}
.week-footer1{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.active {
  color: #ffffff;
  background-color: #014479!important;
}
</style>
